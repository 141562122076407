import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';

function App() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  const orderPath = '/order';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    if (userType == 2 ) {
      navigate(orderPath);
    }
  }, [userId, navigate]);

  const [websiteData, setwebsiteData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/web-orders/');
        setwebsiteData(response.data);
        console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);
    return () => clearInterval(intervalId);
    
  }, []);


  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}
    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">All Orders</p>
                    {websiteData && websiteData.data && websiteData.data.website.length > 0 ? (
                        <h5 class="font-weight-bolder"> {websiteData.data.website[0].website_orders}</h5>
                      ) : ( <p>Loading...</p>
                    )}
                   
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                    <i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Delivered</p>
                    {websiteData && websiteData.data && websiteData.data.website.length > 0 ? (
                        <h5 class="font-weight-bolder"> {websiteData.data.website[0].website_delivered}</h5>
                      ) : ( <p>Loading...</p>
                    )}
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                    <i class="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Cod Orders</p>
                    {websiteData && websiteData.data && websiteData.data.website.length > 0 ? (
                        <h5 class="font-weight-bolder"> {websiteData.data.website[0].website_cod}</h5>
                      ) : ( <p>Loading...</p>
                    )}
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                    <i class="ni ni-paper-diploma text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Online Payments</p>
                    {websiteData && websiteData.data && websiteData.data.website.length > 0 ? (
                        <h5 class="font-weight-bolder"> {websiteData.data.website[0].website_online}</h5>
                      ) : ( <p>Loading...</p>
                    )}
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                    <i class="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row mt-5">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Orders</h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">CUSTOMER</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">PHONE</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">DATE</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">ITEMS</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">AMOUNT</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">PAYMENT</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">ORDER DETAILS</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                
                {websiteData && websiteData?.data?.orders && websiteData?.data.orders?.map(website => (

                    <tr>
					            <td>
                        <div class="d-flex px-2 py-1">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{website.order_customer}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                      <span class="text-xs font-weight-bold">{website.order_contact}</span>
					  
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{website.order_time}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{website.order_items}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">₹{website.order_amount}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{website.order_payment}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{website.order_status}</span>
                      </td>
                      <td class="align-middle text-center">
                         <a href={`order-details?id=${website.order_id}`}><button class="btn btn-success btn-sm ms-auto">Details</button></a>
                     
                      </td>
                    </tr>
                    
                    ))}
                    {websiteData !== null && <tr><td colspan="8" style={{textAlign: 'center', padding: '40px'}}>No Data</td></tr>}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
   
		
        
	
	  

	  
	
      <Footer />
    </div>
  </main>

  </div>
  )
}
export default App;